import Drawer from "../../../components/Drawer";
import ChamadosTable from "../../../components/ChamadosTable";
import React, { useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useHistory } from "react-router";
import api from "../../../services/api";
import { Skeleton } from "primereact/skeleton";
import EquipmentTable from "../../Chamados/EquipmentTable";

const Details = () => {
  const history = useHistory();
  const pat = history.location.pathname.split("/")[2];

  const [loading, setLoading] = useState(true);
  const [equipment, setEquipment] = useState({});
  const [chamados, setChamados] = useState([]);

  useEffect(() => {
    getEquipment();
  }, []);

  const getEquipment = async () => {
    const pat = history.location.pathname.split("/")[2];
    const response = await api.get(`/equipments/getEquipmentByPat/${pat}`);
    setEquipment(response.data);
    setLoading(false);
  };

  return (
    <>
      <div className="tabview-demo">
        <div className="card">
          <div className="p-4 d-flex flex-row align-items-center justify-content-between">
            {loading ? (
              <Skeleton width="50em" height="60px"></Skeleton>
            ) : (
              <h3>{equipment.equipment}</h3>
            )}
            <button
              className="btn btn-primary"
              onClick={() => history.push("/equipamentos")}
            >
              Voltar
            </button>
          </div>
          <TabView>
            <TabPanel header="Detalhes">
              <div className="row">
                {loading ? (
                  <Skeleton
                    className="ms-3"
                    width="50%"
                    height="150px"
                  ></Skeleton>
                ) : (
                  <>
                    <div className="col-md-6">
                      <p>
                        <strong>PAT: </strong>
                        {equipment.pat}
                      </p>
                      <p>
                        <strong>S/N: </strong>
                        {equipment.serial_number}
                      </p>
                      <p>
                        <strong>TIPO: </strong>
                        {equipment.type}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <strong>MODELO: </strong>
                        {equipment?.model.name}
                      </p>
                      <p>
                        <strong>MARCA: </strong>
                        {equipment?.brand.name}
                      </p>
                      {equipment.company && (
                        <p>
                          <strong>Empresa atual: </strong>
                          {equipment.company.name}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel header="Chamados">
              <EquipmentTable pat={pat} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default Details;
