import React, { useEffect, useRef, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import RowsDropdown from "../../components/RowsDropdown";
import ReportFilters from "../../components/ReportFilters/ReportFilters";
import DateRange from "../../components/ToolbarComponents/DateRange";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";
import "./styles.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ChangeAttendanceTime from "../../components/ChangeAttendanceTime";
import CheckedByAdm from "../../components/CheckedByAdm";
import { boolean } from "zod";
import { dateToHour } from "../../utils/masks";
import { constants } from "../../utils/constants";
import CreateCorrectiveForm from "../../components/createCorrectiveForm";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";

const Table = () => {
  const { access_type, email } = useAuth();
  const history = useHistory();
  const [toastOptions, setToastOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inputBeginDate, setInputBeginDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [inputEndDate, setInputEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const [rows, setRows] = useState(10);

  const toast = useRef(null);
  const dt = useRef(null);
  const [corretivas, setCorretivas] = useState([]);
  const [counter, setCounter] = useState([]);
  const [search, setSearch] = useState("");
  const [formModal, setFormModal] = useState(false);
  const [checkModal, setCheckModal] = useState(false);
  const [currentCorrective, setCurrentCorrective] = useState(null);

  const [page, setPage] = useState(1);

  const [sortOrder, setSortOrder] = useState(-1);
  const [sortField, setSortField] = useState("date");
  const ordenar = (sortField, sortOrder) => {
    setSortOrder(sortOrder);
    setSortField(sortField);
  };
  const [showFilter, setShowFilter] = useState(false);
  const [multiEmpresas, setMultiEmpresas] = useState([]);
  const [multiTechs, setMultiTechs] = useState([]);
  const [multiEquips, setMultiEquips] = useState([]);
  const onHideFilter = () => setShowFilter(false);
  const [formCreateCorrective, setFormCreateCorrective] = useState(false);
  const [formDeleteCorrective, setFormDeleteCorrective] = useState(false);

  const onHide = () => {
    setCurrentCorrective(null);
    setFormModal(false);
    setCheckModal(false);
    setFormCreateCorrective(false);
    setFormDeleteCorrective(false);
  };

  useEffect(() => {
    getCorretivas();
  }, [
    page,
    search,
    beginDate,
    endDate,
    sortOrder,
    access_type,
    rows,
    sortField,
    multiEmpresas,
    multiTechs,
    multiEquips
  ]);

  const getCorretivas = async () => {
    setLoading(true);
    const mEmpresas = multiEmpresas.join(",");
    const mTechs = multiTechs.join(",");
    const mEquips = multiEquips?.join(",") ?? "";
    await api
      .get(
        `/corrective?page=${page}&search=${search}&order=${sortOrder}&orderBy=${sortField}&beginDate=${beginDate}&endDate=${endDate}&rows=${rows}&multiEmpresas=${mEmpresas}&multiTechs=${mTechs}&multiEquips=${mEquips}`
      )
      .then((response) => {
        setCorretivas(response.data.data);
        setCounter(response.data.total);
        setLoading(false);
      });
  };

  const changeAttendanceCorrective = async (startDate, endDate) => {
    if (!currentCorrective) {
      return;
    }
    const startTime = dateToHour(startDate);
    const endTime = dateToHour(endDate);
    const attendanceFullTime = `${startTime}-${endTime}`;
    setLoading(true);
    await api.put(`/corrective/update-attendance/${currentCorrective.id}`, {
      attendance: attendanceFullTime
    });
    setLoading(false);
    toast.current.show({
      severity: "success",
      summary: "",
      detail: "Horário alterado com sucesso.",
      life: 5000
    });
    onHide();
    getCorretivas();
  };
  const handleValidateReport = async () => {
    if (!currentCorrective) {
      return;
    }
    setLoading(true);
    await api.post(`/checked`, {
      report_type: "corrective",
      report_id: currentCorrective?.id
    });
    setLoading(false);
    toast.current.show({
      severity: "success",
      summary: "",
      detail: "Relatório validado com sucesso.",
      life: 5000
    });
    onHide();
    getCorretivas();
  };

  useEffect(() => {
    if (Object.keys(toastOptions).length !== 0) {
      toast.current.show({
        severity: toastOptions.severity,
        summary: "",
        detail: toastOptions.detail,
        life: 5000
      });
    }
  }, [toastOptions]);

  const exportValidation = async () => {
    toast.current.show({
      severity: "info",
      summary: "",
      detail: "Gerando excel, aguarde...",
      life: 5000
    });
    setLoading(true);
    const mEmpresas = multiEmpresas.join(",");
    const mTechs = multiTechs.join(",");
    const mEquips = multiEquips?.join(",") ?? "";
    api
      .get(
        `/corrective/export/excel-validation?beginDate=${beginDate}&endDate=${endDate}&search=${search}&multiEmpresas=${mEmpresas}&multiTechs=${mTechs}&multiEquips=${mEquips}`,
        {
          responseType: "blob"
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const startDate =
          beginDate ||
          new Date(new Date().getFullYear(), 0, 1).toLocaleDateString();
        const finalDate =
          endDate ||
          new Date(new Date().getFullYear(), 11, 31).toLocaleDateString();
        link.setAttribute(
          "download",
          `corretivas-validation-${startDate}-${finalDate}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail:
            "Ocorreu um erro ao gerar excel, contate um administrador: " +
            error.message,
          life: 5000
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportExcel = async () => {
    toast.current.show({
      severity: "info",
      summary: "",
      detail: "Gerando excel, aguarde...",
      life: 5000
    });
    const mEmpresas = multiEmpresas.join(",");
    const mTechs = multiTechs.join(",");
    const mEquips = multiEquips?.join(",") ?? "";
    setLoading(true);

    api
      .get(
        `/corrective/export/excel?beginDate=${beginDate}&endDate=${endDate}&search=${search}&multiEmpresas=${mEmpresas}&multiTechs=${mTechs}&multiEquips=${mEquips}`,
        {
          responseType: "blob"
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const startDate =
          beginDate ||
          new Date(new Date().getFullYear(), 0, 1).toLocaleDateString();
        const finalDate =
          endDate ||
          new Date(new Date().getFullYear(), 11, 31).toLocaleDateString();
        link.setAttribute(
          "download",
          `corretivas-${startDate}-${finalDate}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail:
            "Ocorreu um erro ao gerar excel, contate um administrador: " +
            error.message,
          life: 5000
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exportar validações"
          style={{
            backgroundColor: "#086f02",
            outline: 0,
            border: "none",
            marginRight: "10px"
          }}
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportValidation}
        />
        <Button
          label="Exportar"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportExcel}
        />
      </React.Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Novo"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => setFormCreateCorrective(true)}
        />
      </React.Fragment>
    );
  };

  function dataConstruct(data) {
    if (!data) {
      return "";
    }

    let splitT = data.split("T");
    let split = splitT[0].split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
  }

  const downloadReport = async (rowData) => {
    setLoading(true);

    api
      .get("/report/corrective/pdf/" + rowData.id, {
        responseType: "blob"
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const reportNumber = rowData?.report?.padStart(6, 0);
        link.setAttribute("download", reportNumber + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData && rowData?.parts && rowData?.parts?.length > 0 && (
          <Button
            icon="pi pi-money-bill"
            style={{
              backgroundColor: "green",
              outline: 0,
              border: "none",
              marginLeft: 6
            }}
            className="p-button-rounded p-button text-white"
            onClick={() =>
              window
                .open(`/pdf/orcamento;${rowData.id};corrective`, "_blank")
                .focus()
            }
          />
        )}
        {
          <Button
            icon="pi pi-file-pdf"
            style={{
              backgroundColor: "#7f0b0b",
              outline: 0,
              border: "none",
              marginLeft: 6
            }}
            className="p-button-rounded p-button text-white"
            onClick={() =>
              window.open(`/pdf/corrective;${rowData.id}`, "_blank").focus()
            }
          />
        }
        {constants.masterEmails.includes(email) && (
          <Button
            icon="pi pi-clock"
            style={{
              backgroundColor: "#7f0b0b",
              outline: 0,
              border: "none",
              marginLeft: 6
            }}
            className="p-button-rounded p-button text-white"
            onClick={() => {
              setCurrentCorrective(rowData);
              setFormModal(true);
            }}
          />
        )}
        {access_type === "Admin" && (
          <Button
            icon="pi pi-check-square"
            style={{
              backgroundColor: "#086f02",
              outline: 0,
              border: "none",
              marginLeft: 6
            }}
            className="p-button-rounded p-button text-white"
            onClick={() => {
              setCurrentCorrective(rowData);
              setCheckModal(true);
            }}
          />
        )}

        {constants.masterEmails.includes(email) && (
          <Button
            tooltip="Editar"
            tooltipOptions={{ position: "top" }}
            icon="pi pi-pencil"
            style={{
              outline: 0,
              border: "none",
              marginLeft: 6
            }}
            className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
            onClick={() => {
              setCurrentCorrective(rowData);
              setFormCreateCorrective(true);
            }}
          />
        )}
        {constants.masterEmails.includes(email) && (
          <Button
            tooltip="Excluir"
            tooltipOptions={{ position: "top" }}
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger text-white p-mr-2 me-2"
            onClick={() => {
              setCurrentCorrective(rowData);
              setFormDeleteCorrective(true);
            }}
          />
        )}
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header chamados-table-header">
      <div className="pt-2">
        <h5 className="p-m-0">Corretivas</h5>
        <span className="p-input-icon-left d-flex">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
            placeholder="Busca..."
          />
          {access_type === "Admin" && (
            <Button
              style={{ color: "white", marginLeft: 8 }}
              icon="pi pi-filter"
              onClick={() => setShowFilter(true)}
            />
          )}
        </span>
      </div>
      <DateRange
        inputBeginDate={inputBeginDate}
        setInputBeginDate={setInputBeginDate}
        beginDate={beginDate}
        setBeginDate={setBeginDate}
        setInputEndDate={setInputEndDate}
        setEndDate={setEndDate}
        inputEndDate={inputEndDate}
        endDate={endDate}
      />
    </div>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const renderServiceNumber = (rowData) => {
    if (!rowData?.service_number) return "Nenhum encontrado.";

    const year = rowData?.service_date?.split("-")[0];
    return year + "." + rowData.service_number;
  };

  const handleDeleteConfirmCorrective = async () => {
    try {
      await api.delete(`/corrective/delete-corrective/${currentCorrective.id}`);
      toast.current.show({
        severity: "success",
        summary: "Sucesso",
        detail: "Corretiva excluída com sucesso.",
        life: 5000
      });
      getCorretivas();
      setFormDeleteCorrective(false);
      onHide();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Ocorreu um erro ao excluir a corretiva.",
        life: 5000
      });
    }
  };

  return (
    <>
      <div className="datatable-responsive-demo">
        <Toast style={{ zIndex: 1101 }} ref={toast} />
        <div className="card">
          <Toolbar className="p-mb-4" right={rightToolbarTemplate} />
          {constants.masterEmails.includes(email) && (
            <Toolbar className="p-mb-4" left={leftToolbarTemplate} />
          )}

          <DataTable
            ref={dt}
            value={corretivas}
            dataKey="id"
            rows={rows}
            header={header}
            sortOrder={sortOrder}
            sortField={sortField}
            onSort={({ sortField, sortOrder }) => ordenar(sortField, sortOrder)}
            className={"p-datatable-responsive-demo"}
            loading={loading}
          >
            <Column headerStyle={{ width: "3rem" }} />
            <Column
              headerStyle={{ width: "10rem" }}
              field="report"
              sortable={true}
              header="Relatório"
              body={(rowData) =>
                rowData?.auto_report
                  ? String(rowData?.auto_report)?.padStart(6, 0)
                  : rowData?.report?.padStart(6, 0)
              }
            />
            <Column
              headerStyle={{ width: "10rem" }}
              field="report"
              header="Chamado"
              body={(rowData) => renderServiceNumber(rowData.service_call)}
            />
            <Column field="company.name" header="Empresa" />
            <Column
              field="service_call.equipment.equipment"
              header="Equipamento"
            />
            <Column
              field="date"
              header="Data"
              sortable={true}
              body={(rowData) => dataConstruct(rowData.date)}
            />
            <Column field="created_by.name" header="Criado por" />
            <Column header="Ações" body={actionBodyTemplate} />
          </DataTable>
          <div
            className={"d-flex justify-content-center align-items-center p-3"}
          >
            <div>
              <Pagination
                className="pagination"
                color={"primary"}
                boundaryCount={1}
                count={Math.ceil(counter / rows)}
                onChange={handleChangePage}
              />
            </div>
            <RowsDropdown rows={rows} setRows={setRows} />
          </div>
        </div>
      </div>

      <ReportFilters
        setMultiEmpresas={setMultiEmpresas}
        setMultiTechs={setMultiTechs}
        setMultiEquips={setMultiEquips}
        isVisible={showFilter}
        onHide={onHideFilter}
      />
      <ChangeAttendanceTime
        onHide={onHide}
        visible={formModal}
        attendance={currentCorrective?.attendance || ""}
        onSubmit={(startDate, endDate) =>
          changeAttendanceCorrective(startDate, endDate)
        }
      />
      <CheckedByAdm
        checks={currentCorrective?.checks}
        costumerChecks={currentCorrective?.costumer_checks}
        onHide={onHide}
        onSubmit={handleValidateReport}
        visible={checkModal}
      />
      <CreateCorrectiveForm
        currentCorrective={currentCorrective}
        onHide={onHide}
        visible={formCreateCorrective}
        toast={toast}
        setLoading={setLoading}
        getCorretivas={getCorretivas}
      />

      <DeleteDialog
        visible={formDeleteCorrective}
        onHide={() => setFormDeleteCorrective(false)}
        onConfirm={handleDeleteConfirmCorrective}
        message="Deseja realmente excluir a corretiva?"
        header="Confirmar Exclusão de Corretiva"
        confirmLabel="Excluir"
        cancelLabel="Cancelar"
      />
    </>
  );
};

export default Table;
